<template>
  <!--begin::List Widget 4-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark">Log Activity</h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2">
      <template v-if="!isBusy">
        <template v-if="list.length > 0">
          <!--begin::Item-->
          <div v-for="(item, i) in list" class="d-flex align-items-center mb-10" v-bind:key="i">
            <!--begin::Bullet-->
            <span
              class="bullet bullet-bar align-self-stretch bg-primary"
            ></span>
            <!--end::Bullet-->
            <!--begin::Checkbox-->
            <label
              class="checkbox checkbox-lg checkbox-single flex-shrink-0 m-0 mx-4 checkbox-light-primary"
            >
              <input type="checkbox" name="" value="1" />
              <span></span>
            </label>
            <!--end::Checkbox-->
            <!--begin::Text-->
            <div class="d-flex flex-column flex-grow-1">
              <a
                class="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1"
              >
                {{ item.event | title }} {{ item.title | title }}
              </a>
              <span class="text-muted font-weight-bold">
                {{ item.tanggal | timeAgo }}
              </span>
            </div>
            <!--end::Text-->
            <!--begin::Dropdown-->
            <b-button variant="light" size="sm" class="btn-icon">
              <span class="svg-icon svg-icon-md svg-icon-success m-0">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Navigation/Arrow-right.svg"/>
                <!--end::Svg Icon-->
              </span>
            </b-button>
            <!--end::Dropdown-->
          </div>
          <!--end:Item-->
        </template>
        <div v-else class="border rounded p-3 mb-5 text-center">
          <span class="text-dark-75 font-size-lg font-weight-bolder">Tidak ada data Aktivitas</span>
        </div>
      </template>
      <div class="w-full p-0" v-else>
        <ContentLoader>
          <rect x="0" y="0" rx="3" ry="3" width="100%" height="15" />
          <rect x="0" y="25" rx="3" ry="3" width="100%" height="15" />
          <rect x="0" y="50" rx="3" ry="3" width="100%" height="15" />
          <rect x="0" y="75" rx="3" ry="3" width="100%" height="15" />
          <rect x="0" y="100" rx="3" ry="3" width="100%" height="15" />
        </ContentLoader>
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end: List Widget 4-->
</template>

<script>
import { mapGetters } from "vuex";
import { ContentLoader } from 'vue-content-loader'
import ProfileService from "@/core/services/api/profile.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "dashboard-list-1",
  components: {
    ContentLoader
  },
  data() {
    return {
      isBusy: true,
      list: [{
        title: "activity",
        tanggal: "2022-02-13"
      }]
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  methods: {
    getData () {
      ProfileService.getLogActivity()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.list = []
                this.isBusy = false
                return;
            }
            this.list = res.data.data
            this.isBusy = false
        })
        .catch((err) => {
          this.list = []
          this.isBusy = false
          return ErrorService.status(err)
        })
    },
  },
  mounted() {
    this.getData()
  },
};
</script>
