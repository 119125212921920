<template>
  <!--begin::Mixed Widget 1-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 bg-primary py-5">
      <h3 class="card-title font-weight-bolder text-white">
        Quick Action
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body bg-white p-0 position-relative overflow-hidden">
      <!--begin::Chart-->
      <div class="card-rounded-bottom bg-primary p-10"></div>
      <!--end::Chart-->
      <!--begin::Stats-->
      <div class="card-spacer mt-n25">
        <!--begin::Row-->
        <div class="row m-0">
          <div @click="$router.push({ name: 'statistik-user'})" class="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7 cursor-pointer">
            <span class="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
              <inline-svg src="media/svg/icons/Media/Equalizer.svg" />
            </span>
            <a class="text-warning font-weight-bold font-size-h6">
              Statistik
            </a>
          </div>
          <div @click="$router.push({ name: 'target'})" class="col bg-light-primary px-6 py-8 rounded-xl mb-7 cursor-pointer">
            <span class="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
              <inline-svg src="media/svg/icons/General/Thunder-move.svg" />
            </span>
            <a class="text-primary font-weight-bold font-size-h6 mt-2">
              Target
            </a>
          </div>
        </div>
        <!--end::Row-->
        <!--begin::Row-->
        <div class="row m-0">
          <div @click="$router.push({ name: 'public-statistik'})" class="col bg-light-danger px-6 py-8 rounded-xl mr-7 cursor-pointer">
            <span class="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
              <inline-svg src="media/svg/icons/Design/Layers.svg" />
            </span>
            <a class="text-danger font-weight-bold font-size-h6 mt-2">
              Public Data
            </a>
          </div>
          <div @click="$router.push({ name: 'bug-report-histori'} )" class="col bg-light-success px-6 py-8 rounded-xl cursor-pointer">
            <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
              <inline-svg src="media/svg/icons/Communication/Urgent-mail.svg" />
            </span>
            <a class="text-success font-weight-bold font-size-h6 mt-2">
              Bug Reports
            </a>
          </div>
        </div>
        <!--end::Row-->
      </div>
      <!--end::Stats-->
      <!--end::Body-->
    </div>
    <!--end::Mixed Widget 1-->
  </div>
</template>

<script>

export default {
  name: "dashboard-mixed-1",
  data() {
    return {
      //
    };
  },
};
</script>
